:root {
  --main-font-family: "Roboto", sans-serif;
  --sub-font-family: "Nunito", sans-serif;
  --primary-color: #171717;
  --secondary-color: #202020;
  --extra-color: #262328;
  --default-text-color: white;
  --link-hover-color: #533e75;
}

.App {
  text-align: center;
}

.button_main {
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 2.2rem;
  border-radius: 3rem;
  margin-top: 3rem;
  margin-bottom: 11rem;
  letter-spacing: 0.1rem;
}

.navigate-btn {
  font-size: 0.9rem;
  font-weight: bold;
  padding: 1rem 2.2rem;
  border-radius: 50px;
  margin-top: 2rem;
  margin-bottom: 8rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.button_main:hover,
.navigate-btn:hover,
.navigate-btn-back:hover {
  background-color: var(--link-hover-color);
  color: var(--default-text-color);
}

body {
  font-family: var(--main-font-family);
  background-color: var(--primary-color);
  color: white;
}

h1,
h2,
h3 {
  text-align: center;
}

.card {
  background-color: transparent;
  border: transparent;
  font-family: var(--sub-font-family);
}

.card-title {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: white;
}

.card-text {
  font-weight: normal;
  font-size: 1rem;
  color: white;
}

.card-body {
  padding-bottom: 0rem;
}

.sub-heading {
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  margin: 1.2rem auto;
  letter-spacing: 0.3rem;
}
